<template>
  <EntityList
    :config="config"
    :columns="columns"
    single-edit
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    authority="Congress_Management_Congress_Service"
    desc-sort
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../CongressServiceStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
    }
  },
  computed: {
    congressID() {
      return this.$route.params.id
    },
    config() {
      return {
        store: storeModule,
        module: 'congress-services',
        endpoint: 'congress-services',
        route: 'congress-services',
        title: {
          single: this.$t('Service Add'),
          plural: this.$t('Services'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'city', label: this.$t('city'), sortable: false },
        { key: 'businessSegment', label: this.$t('Business Segment'), sortable: false },
        { key: 'phone1', label: this.$t('telephone'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'createdfrom', type: 'date' },
        { key: 'createdto', type: 'date' },
      ]
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('Country'),
          options: this.countryOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$t('Created From'),
          maxDateFieldIdx: '2',
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$t('Created To'),
          minDateFieldIdx: '1',
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
}
</script>
